import { Component, Output, EventEmitter, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer, Subject } from "rxjs";

import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';

import { BrandService } from '../../pages/brands/brands.service';
import { CategoryService } from '../../pages/categories/category.service';
import { ManufacturerService } from '../../pages/manfacturer/manufacturer.service';
import { TagService } from '../../pages/tags/tags.service';
import { WebsiteService } from '../../pages/websites/websites.service';
import { ProductService } from '../../pages/products/products.service';
import { FranchiseBrandService } from '../../pages/franchisebrand/franchisebrand.service';
import { FranchiseGroupService } from '../../pages/franchisegroup/franchisegroup.service';
import { VideoService } from '../../pages/videos/video.service';
import { PdfService } from '../../pages/pdf/pdf.service';
import { ImageService } from '../../pages/images/image.service';


import { AuthService } from '../auth/auth.service';
import { NGXToastrService } from '../../shared/services/toastr.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};
  notifications: any = [];
  activities: any = [];
  read: any = [];
  notifications_previous: string;
  notifications_next: string;
  notifications_count: string;
  query: string;
  count: string;
  rows = [];
  closeResult: string;
  subscription: any;
  progress: any = [];
  adminCheck: boolean;
  set_interval: any;
  import_progress_check: any;
  update_progress_check: any;

  constructor(private router: Router, private toasterService: NGXToastrService, public translate: TranslateService, private layoutService: LayoutService, private configService: ConfigService, private authService: AuthService, private modalService: NgbModal, private productService: ProductService, private brandService: BrandService, private categoryService: CategoryService, private manufacturerService: ManufacturerService, private tagService: TagService, private websiteService: WebsiteService, private cd: ChangeDetectorRef, private franchiseBrandService: FranchiseBrandService, private franchiseGroupService: FranchiseGroupService, private videoService: VideoService, private imageService: ImageService, private pdfService: PdfService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.getNotifications();
    this.getNotificationsCount();
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { this.checkImportProgress();}
    });

  }

  ngOnInit() {
    this.set_interval = setInterval(() => { this.cd.detectChanges(); }, 100);
    this.config = this.configService.templateConf;
    
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user.is_admin == true) {
      this.adminCheck = true
    } else {
      this.adminCheck = false
    }

    this.productService._OnMessageReceivedSubject.subscribe((r) => {
      this.getNotifications();
      this.getNotificationsCount();
    });

  }

  checkImportProgress() {
    this.import_progress_check = setInterval(() => {
      let importProduct=localStorage.getItem('importProduct');
      if (importProduct == "true"){
          this.getImportProgress();
      } 
    },5000)

    this.update_progress_check = setInterval(() => {
      let updateProduct=localStorage.getItem('updateProduct');
      if (updateProduct == "true"){
          this.getUpdateProgress();
      } 
    },5000)
  }


  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // This function is used in open
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      const dir = this.config.layout.dir;
      if (dir === "rtl") {
        this.placement = "bottom-left";
      } else if (dir === "ltr") {
        this.placement = "bottom-right";
      }
    }
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  getNotifications() {
    this.query = "?is_read=False&recent=True"
    this.productService.getNotifications(this.query).subscribe(data => {
      this.notifications = data;
    });
  }

  getNotificationsCount() {
    this.productService.getNotificationsCount().subscribe(data => {
      this.count = data['count'];
    });
  }

  readNotifications() {
    this.productService.readNotification().subscribe(data => {
      this.productService.getNotifications(this.query).subscribe(data => {
        this.notifications = data;
      });
    });
  }

  updateNotifications(id) {
    this.productService.getNotification(id).subscribe(data => {
      this.rows = data;
      this.rows['is_read'] = true
      this.productService.updateNotification(this.rows).subscribe(data => {
        this.productService.getNotifications(this.query).subscribe(data => {
          this.notifications = data;
          this.getNotificationsCount();
        });
      });
    });
  }

  deleteModal(id, modules, value, action, unapproved_id) {
    if (action == "Create") {
      if (modules == "Product") {
        this.productService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.productService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Brand") {
        this.brandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.brandService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Catalog") {
        // Implement Catalogs
      } else if (modules == "Category") {
        this.categoryService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.categoryService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Tag") {
        this.tagService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.tagService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Website") {
        this.websiteService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.websiteService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Manufacturer") {
        this.manufacturerService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.manufacturerService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseBrand") {
        this.franchiseBrandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.franchiseBrandService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseGroup") {
        this.franchiseGroupService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.franchiseGroupService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Image") {
        this.imageService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.imageService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Video") {
        this.videoService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.videoService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "PDF") {
        this.pdfService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.pdfService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
    } else if (action == "Update") {
      if (modules == "Product") {
        this.productService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.productService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Brand") {
        this.brandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.brandService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Catalog") {
        // Implement Catalogs
      } else if (modules == "Category") {
        this.categoryService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.categoryService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Tag") {
        this.tagService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.tagService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Website") {
        this.websiteService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.websiteService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Manufacturer") {
        this.manufacturerService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.manufacturerService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseBrand") {
        this.franchiseBrandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.franchiseBrandService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseGroup") {
        this.franchiseGroupService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.franchiseGroupService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Image") {
        this.imageService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.imageService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Video") {
        this.videoService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.videoService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "PDF") {
        this.pdfService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.pdfService.rejected(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } 



    }

  }
 

  updateModal(id, modules, value, action, unapproved_id) {

    if (action == "Create") {
      if (modules == "Product") {
        this.productService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.productService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Brand") {
        this.brandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.brandService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Catalog") {
        // Implement Catalogs
      } else if (modules == "Category") {
        this.categoryService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.categoryService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Tag") {
        this.tagService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.tagService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Website") {
        this.websiteService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.websiteService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Manufacturer") {
        this.manufacturerService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.manufacturerService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }else if (modules == "FranchiseBrand") {
        this.franchiseBrandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.franchiseBrandService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseGroup") {
        this.franchiseGroupService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.franchiseGroupService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Image") {
        this.imageService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.imageService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Video") {
        this.videoService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.videoService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "PDF") {
        this.pdfService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.pdfService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } 
    } else if (action == "Update") {
      if (modules == "Product") {
        this.productService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.productService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Brand") {
        this.brandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.brandService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Catalog") {
        // Implement Catalogs
      } else if (modules == "Category") {
        this.categoryService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.categoryService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Tag") {
        this.tagService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.tagService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Website") {
        this.websiteService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.websiteService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Manufacturer") {
        this.manufacturerService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.manufacturerService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseBrand") {
        this.franchiseBrandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.franchiseBrandService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseGroup") {
        this.franchiseGroupService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.franchiseGroupService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Image") {
        this.imageService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.imageService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "Video") {
        this.videoService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.videoService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "PDF") {
        this.pdfService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['unapproved_id'] = unapproved_id
          this.rows['hidden'] = value
          this.pdfService.update(this.rows).subscribe(data => {
            this.rows = data;
          });
        });
      } 
    } else if (action == "Delete") {
      
      if (modules == "Product") {
        
        this.productService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.productService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Brand") {
        this.brandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.brandService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Catalog") {
        // Implement Catalogs
      } else if (modules == "Category") {
        this.categoryService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.categoryService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Tag") {
        this.tagService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.tagService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Website") {
        this.websiteService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.websiteService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      } else if (modules == "Manufacturer") {
        this.manufacturerService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.manufacturerService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseBrand") {
        this.franchiseBrandService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.franchiseBrandService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "FranchiseGroup") {
        this.franchiseGroupService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.franchiseGroupService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
      else if (modules == "PDF") {
        this.pdfService.get(id).subscribe(data => {
          this.rows = data;
          this.rows['hidden'] = value
          this.pdfService.delete(this.rows['id']).subscribe(data => {
            this.rows = data;
          });
        });
      }
    }
  }

  logOut() {
    this.authService.logout();
  }

  getImportProgress() {
    if(this.import_progress_check){
      let importProduct=localStorage.getItem('importProduct');
       localStorage.setItem('importProduct','false'); 
       clearInterval(this.import_progress_check);
    }
    
    this.productService.getImportProgress().subscribe(resp => {
      if (resp) {
        this.progress = resp;
        //console.log(this.progress);
        if(this.progress && this.progress.length>0){
          if(this.progress[0].has_error==1 && this.progress[0].productsCount==0){           
            const msg = "Products Import Failed. Please check your inbox for more details."

             this.toasterService.typeError(
              "Import Product",
              msg
            ); 
          }else{
            let user = JSON.parse(localStorage.getItem('currentUser'));
            let msg_complete = 'Products Imported Successfully';

            if (user.is_admin == false ){
              msg_complete = 'product(s) import request is submitted for Admin’s approval'
            }

            if(this.progress[0].has_error==1){
              const msg = this.progress[0].productsCount +" "+msg_complete+ " but have some errors as well. Please check your inbox for more details."
              this.toasterService.typeSuccessCustom('Import Product', msg) 
            }else{
              const msg = this.progress[0].productsCount + " "+ msg_complete;
              this.toasterService.typeSuccessCustom('Import Product', msg) 
            }            
          } 
           
        }
        

        
        
      }
    })
  }

  getUpdateProgress() {
    if(this.update_progress_check){
      let updateProduct=localStorage.getItem('updateProduct');
       localStorage.setItem('updateProduct','false'); 
       clearInterval(this.update_progress_check);
    }
    
    this.productService.getUpdateProgress().subscribe(resp => {
      if (resp) {
        this.progress = resp;
        //console.log(this.progress);
        if(this.progress && this.progress.length>0){
          if(this.progress[0].has_error==1 && this.progress[0].productsCount==0){           
            const msg = "Products Update Failed. Please check your inbox for more details."

             this.toasterService.typeError(
              "Update Products",
              msg
            ); 
          }else{
            let user = JSON.parse(localStorage.getItem('currentUser'));
            let msg_complete = 'Products Updated Successfully';

            if (user.is_admin == false ){
              msg_complete = 'product(s) update request is submitted for Admin’s approval'
            }

            if(this.progress[0].has_error==1){
              const msg = this.progress[0].productsCount +" "+msg_complete+ " but have some errors as well. Please check your inbox for more details."
              this.toasterService.typeSuccessCustom('Update Products', msg) 
            }else{
              const msg = this.progress[0].productsCount + " "+ msg_complete;
              this.toasterService.typeSuccessCustom('Update Products', msg) 
            }            
          } 
           
        }        
      }
    })
  }

  ngOnDestroy() {
    if(this.set_interval){
      clearInterval(this.set_interval);
    }
    if(this.import_progress_check){
      clearInterval(this.import_progress_check);
    }
    if(this.update_progress_check){
      clearInterval(this.update_progress_check);
    }
    this.productService._OnMessageReceivedSubject.unsubscribe();
  }

}
