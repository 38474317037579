import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: () => import('../../changelog/changelog.module').then(m => m.ChangeLogModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../../pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'products',
    loadChildren: () => import('../../pages/products/products.module').then(m => m.ProductsPagesModule)
  },
  {
    path: 'products/quantity',
    loadChildren: () => import('../../pages/products/products.module').then(m => m.ProductsPagesModule)
  },
  {
    path: 'products/missing_seo',
    loadChildren: () => import('../../pages/products/products.module').then(m => m.ProductsPagesModule)
  },
  {
    path: 'products/missing_gtin',
    loadChildren: () => import('../../pages/products/products.module').then(m => m.ProductsPagesModule)
  },
  {
    path: 'products/missing_description',
    loadChildren: () => import('../../pages/products/products.module').then(m => m.ProductsPagesModule)
  },
  {
    path: 'products/missing_images',
    loadChildren: () => import('../../pages/products/products.module').then(m => m.ProductsPagesModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('../../pages/brands/brands.module').then(m => m.BrandsPagesModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('../../pages/categories/category.module').then(m => m.CategoryPagesModule)
  },
  {
    path: 'websites',
    loadChildren: () => import('../../pages/websites/websites.module').then(m => m.WebsitesPagesModule)
  },
  {
    path: 'catalogs',
    loadChildren: () => import('../../pages/catalogs/catalogs.module').then(m => m.CatalogsPagesModule)
  },
  {
    path: 'manufacturer',
    loadChildren: () => import('../../pages/manfacturer/manufacturer.module').then(m => m.ManufacturerPagesModule)
  },
  {
    path: 'permissions',
    loadChildren: () => import('../../pages/roles/roles.module').then(m => m.RolesPagesModule)
  },
  {
    path: 'role',
    loadChildren: () => import('../../pages/role/roles.module').then(m => m.RolePagesModule)
  },
  {
    path: 'assignroles',
    loadChildren: () => import('../../pages/assignRoles/roles.module').then(m => m.AssingRolePagesModule)
  },
  {
    path: 'fields',
    loadChildren: () => import('../../pages/productPerm/perm.module').then(m => m.PermPagesModule)
  },
  {
    path: 'tags',
    loadChildren: () => import('../../pages/tags/tags.module').then(m => m.TagPagesModule)
  },
  {
    path: 'images',
    loadChildren: () => import('../../pages/images/image.module').then(m => m.ImagePagesModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('../../pages/videos/video.module').then(m => m.VideoPagesModule)
  },
  {
    path: 'pdf',
    loadChildren: () => import('../../pages/pdf/pdf.module').then(m => m.PDFPagesModule)
  },
  {
    path: 'franchisegroup',
    loadChildren: () => import('../../pages/franchisegroup/franchisegroup.module').then(m => m.FranchiseGroupPagesModule)
  },
  {
    path: 'franchisebrand',
    loadChildren: () => import('../../pages/franchisebrand/franchisebrand.module').then(m => m.FranchiseBrandPagesModule)
  },
  {
    path: 'advancedpricing',
    loadChildren: () => import('../../pages/advanced-pricing/advanced-pricing.module').then(m => m.AdvancedPricingModule)
  },
  {
    path: '',
    redirectTo: 'user/dashboard',
    pathMatch: 'full',
  }
];