import { Component, OnInit, ElementRef, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ActivityService } from '../services/activity.service';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {

  layoutSub: Subscription;
  isOpen = false;
  activities: any = [];
  query: string;

  @ViewChild('sidebar', {static: false}) sidebar: ElementRef;

  ngOnInit() {
    this.getRecentActivities();
  }

  constructor(private router: Router,
        private route: ActivatedRoute, private elRef: ElementRef,
    private renderer: Renderer2,
    private layoutService: LayoutService,
    private activityService: ActivityService) {

    this.layoutSub = layoutService.changeEmitted$.subscribe(
      value => {
        if (this.isOpen) {
          this.renderer.removeClass(this.sidebar.nativeElement, 'open');
          this.isOpen = false;
        }
        else {
          this.renderer.addClass(this.sidebar.nativeElement, 'open');
          this.isOpen = true;
        }
      });
  }

  ngOnDestroy() {
    if(this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  onClose() {
    this.renderer.removeClass(this.sidebar.nativeElement, 'open');
    this.isOpen = false;
  }

  renderToActivities(){
      let link = '/user/activities/';
      this.onClose();
      this.router.navigate([link]);
  }

  getActivities() {
    this.activityService.getAll().subscribe(data => {
      this.activities = data;
    });
  }

  getRecentActivities() {
    this.query = "?recent=True"
    this.activityService.getRecentActivities(this.query).subscribe(data => {
      this.activities = data;
    });
  }

}
