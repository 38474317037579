import { Injectable } from "@angular/core";
// import { BaseHttpService } from '../../services/BaseHttpService';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../../constants/app-config";

import { Observable, of, Subject } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Page } from './page';
import { Router } from '@angular/router';
// import { Adapter } from './Adapter';

// import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: "root"
})
export class ProductService {
  /**
   * Holds the shared reference of the subject.
  */
  _OnMessageReceivedSubject: Subject<string>;
  
  constructor(
    private http: HttpClient, // private ts: ToastrService
    private router: Router


  ) {
    // Creates a new subject.
    this._OnMessageReceivedSubject = new Subject<string>();
    // super(http);
  }

   /**
 * emits a message. 
 */
  emitMessageReceived(msg: string): void {
    this._OnMessageReceivedSubject.next(msg);
  }

  add(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_Products}`, record).pipe(
      map(x => x),
      tap((newP: any) => console.log(`added record w/ id=${newP}`)),
      catchError(this.handleError<any>("add"))
    );
  }

  addFieldChanges(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_ProductsApproved}`, record).pipe(
      map(x => x),
      tap((newP: any) => console.log(`added record w/ id=${newP}`)),
      catchError(this.handleError<any>("add"))
    );
  }

  manualExport(): Observable<any> {
    return this.http.get<any>(`${AppConfig.URL_ProductsExport}`).pipe(
      map(x => x),
      tap((newP: any) => console.log(`success`)),
      catchError(this.handleError<any>("add"))
    );
  }

  removeFile(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_REMOVE_FILE}`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("removed file"))
    );
  }

  importProducts(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_Import_Products}`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("import"))
    );
  }

  import_product_images(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_Products}associate/images/`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("import"))
    );
  }

  delete_products(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_Products}delete/products/`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("import"))
    );
  }
  importUpdateProducts(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_Import_Update_Products}`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("import"))
    );
  }

  deleteUpdateProducts(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_Import_Delete_Products}`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("delete"))
    );
  }

  approve(record: any): Observable<any> {
    return this.http.put(`${AppConfig.URL_Products}${record["id"]}/approve/`, record).pipe(
      tap(_ => this.log(`updated record id=${record["id"]}`)),
      catchError(this.handleError<any>('updateRecord'))
    );
  }

  getUnapprove(id) {
    return this.http.get<any>(`${AppConfig.URL_Products}${id}/unapprove/`).pipe(
      map(x => x),
      tap(_ => console.log(`get record=${id}`)),
      catchError(this.handleError<any>('getRecord'))
    );
  }

  updateUnapprove(record: any): Observable<any> {
    return this.http.put(`${AppConfig.URL_Products}${record["id"]}/unapprove/`, record).pipe(
      tap(_ => this.log(`updated record id=${record["id"]}`)),
      catchError(this.handleError<any>('updateRecord'))
    );
  }

  addAdapters(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_Adapters}`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("Adapters"))
    );
  }

  addCatalog(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_CATALOGS}`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("Adapters"))
    );
  }

  addExportAdapters(record: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.URL_EXPORT_Adapters}`, record).pipe(
      map(x => x),
      catchError(this.handleError<any>("Adapters"))
    );
  }

  getFieldPermissions(id) {
    return this.http
      .get<any>(`${AppConfig.URL_ProductPermissionCheck + "?role="}${id}`)
      .pipe(
        map(x => x),
        tap(_ => console.log(`get record=${id}`)),
        catchError(this.handleError<any>("getRecord"))
      );
  }

  getImportProgress() {
    return this.http.get<any[]>(`${AppConfig.IMPORT_PROGRESS}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getUpdateProgress() {
    return this.http.get<any[]>(`${AppConfig.UPDATE_PROGRESS}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAdapters() {
    return this.http.get<any[]>(`${AppConfig.URL_Adapters}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getExportAdapters() {
    return this.http.get<any[]>(`${AppConfig.URL_EXPORT_Adapters}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getNotifications(params) {
    return this.http.get<any[]>(`${AppConfig.URL_Notifications}${params}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched notifications")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getNotificationsCount() {
    return this.http.get<any[]>(`${AppConfig.URL_NotificationsCount}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched notifications")),
      catchError(this.handleError("getRecord", []))
    );
  }

  updateNotification(record: any): Observable<any> {
    return this.http
      .put(`${AppConfig.URL_Notifications}${record["id"]}/`, record)
      .pipe(
        tap(_ => this.log(`updated record id=${record["id"]}`)),
        catchError(this.handleError<any>("updateRecord"))
      );
  }

  getNotification(id) {
    return this.http.get<any>(`${AppConfig.URL_Notifications}${id}/`).pipe(
      map(x => x),
      tap(_ => console.log(`get record=${id}`)),
      catchError(this.handleError<any>("getRecord"))
    );
  }

  readNotification() {
    return this.http.get<any[]>(`${AppConfig.URL_ReadNotifications}`).pipe(
      map(x => x),
      tap(_ => console.log("read notifications")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getActivityNotification() {
    return this.http.get<any[]>(`${AppConfig.URL_ActivityNotifications}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched activity notifications")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAll() {

    return this.http.get<any[]>(`${AppConfig.URL_Products}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllProducts(page: Page): Observable<any>{
    let current_route = this.router.url;
    let page_number = page.pageNumber+1;
    let page_params = 'page='+page_number;
    let page_url = AppConfig.URL_Products;
    if(page.store!='' && page.store!=null){
      page_url = AppConfig.URL_Products_Store;
      page_params += '&store='+page.store;
    }
    if(current_route.split("/")[2] == 'quantity'){
      page_params += '&missing_quantity=0'
    }
    if(current_route.split("/")[2] == 'missing_seo'){
      page_params += '&missing_seo=null'
    }
    if(current_route.split("/")[2] == 'missing_description'){
      page_params += '&missing_description=null'
    }
    if(current_route.split("/")[2] == 'missing_gtin'){
      page_params += '&missing_gtin=null'
    }
    if(current_route.split("/")[2] == 'missing_images'){
      page_params += '&missing_images=none'
    }
    return this.http.get<any[]>(`${page_url}?${page_params}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getFilteredProducts(params): Observable<any> {
    return this.http
      .get<any[]>(`${AppConfig.URL_Search_Products}?${params}`)
      .pipe(
        map(x => x),
        tap(_ => console.log("fetched record")),
        catchError(this.handleError("getRecord", []))
      );
  }

  getFilteredUnapprovedProducts(params): Observable<any> {
    return this.http
      .get<any[]>(`${AppConfig.URL_Search_Unapproved_Products}?${params}`)
      .pipe(
        map(x => x),
        tap(_ => console.log("fetched record")),
        catchError(this.handleError("getRecord", []))
      );
  }


  get(id) {
    return this.http.get<any>(`${AppConfig.URL_Products}${id}/`).pipe(
      map(x => x),
      tap(_ => console.log(`get record=${id}`)),
      catchError(this.handleError<any>("getRecord"))
    );
  }

  getProductDetail(id) {
    return this.http.get<any>(`${AppConfig.URL_Product_Detail}${id}/`).pipe(
      map(x => x),
      tap(_ => console.log(`get record=${id}`)),
      catchError(this.handleError<any>("getRecord"))
    );
  }

  getFildsToImport() {
    return this.http.get<any[]>(`${AppConfig.URL_Fields}`).pipe(
      map(x => x),
      catchError(this.handleError("getRecord", []))
    );
  }

  getFieldsToExport() {
    return this.http.get<any[]>(`${AppConfig.URL_ExportFields}`).pipe(
      map(x => x),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllExportProducts() {
    return this.http.get<any[]>(`${AppConfig.URL_ExportProducts}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getExportCsv(data) {
    return this.http.post<any[]>(`${AppConfig.URL_ExportCSV}`,data).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }


  getAllImages(imageName:any) {
    if(typeof imageName==='undefined'){
      imageName = '';
    }
    return this.http.get<any[]>(`${AppConfig.URL_Images+ "?name="}${imageName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllVideos(videoName:any) {
    if(typeof videoName==='undefined'){
      videoName = '';
    }
    return this.http.get<any[]>(`${AppConfig.URL_Videos+ "?name="}${videoName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllPdfs(pdfName:any) {
    if(typeof pdfName==='undefined'){
      pdfName = '';
    }
    return this.http.get<any[]>(`${AppConfig.URL_PDF+ "?name="}${pdfName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getUserRole(id) {
    return this.http.get<any>(`${AppConfig.URL_UserRole + "?user="}${id}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllTags(tagName:any) {
    if(typeof tagName==='undefined'){
      tagName = '';
    }
    return this.http.get<any[]>(`${AppConfig.URL_Tags+ "?name="}${tagName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllWebsites(websiteName:any) {
    if(typeof websiteName==='undefined'){
      websiteName = '';
    }
    return this.http.get<any[]>(`${AppConfig.URL_Websites+ "?name="}${websiteName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllCategories(catName: any): Observable<any>  {
    if(typeof catName==='undefined'){
      catName = '';
    }
    return this.http.get<any[]>(`${AppConfig.URL_Categories + "?name="}${catName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllBrands(brandName:any) {
    if(typeof brandName==='undefined'){
      brandName = '';
    }
    return this.http.get<any[]>(`${AppConfig.URL_Brands + "?name="}${brandName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllFranchiseBrands(fbName:any) {
    if(typeof fbName==='undefined'){
      fbName = '';
    }
    return this.http.get<any[]>(`${AppConfig.URL_FranchiseBrand + "?name="}${fbName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getFranchiseBrandsByGroup(fgroups) {
    return this.http.post<any[]>(`${AppConfig.URL_FranchiseBrand_Filter_By_Group}`, fgroups).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  

  getAllFranchiseGroups(fgName:any) {
    if(typeof fgName==='undefined'){
      fgName = '';
    }

    return this.http.get<any[]>(`${AppConfig.URL_FranchiseGroup + "?name="}${fgName}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllProductFamilies() {
    return this.http.get<any[]>(`${AppConfig.URL_ProductFamilies}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getAllTaxes() {
    return this.http.get<any[]>(`${AppConfig.URL_Taxes}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getArchiveFiles() {
    return this.http.get<any[]>(`${AppConfig.URL_ListArchive}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched archived")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getExportArchive(params) {
    return this.http.get<any[]>(`${AppConfig.URL_ExportArchive}${params}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched archive file")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getSuccessArchiveFiles() {
    return this.http.get<any[]>(`${AppConfig.URL_ListSuccessArchive}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched archived")),
      catchError(this.handleError("getRecord", []))
    );
  }

  getSuccessExportArchive(params) {
    return this.http.get<any[]>(`${AppConfig.URL_ExportSuccessArchive}${params}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched archive file")),
      catchError(this.handleError("getRecord", []))
    );
  }

  // delete (record: any | number): Observable<any> {
  //   const id = typeof record === 'number' ? record : record.id;
  //   const url = `${AppConfig.URL_Products}${id}`;

  //   return this.http.delete<Dealer>(url).pipe(
  //     map(x => x["data"] ),
  //     tap(_ => this.log(`deleted dealer id=${id}`)),
  //     catchError(this.handleError<Dealer>('deleteDealer'))
  //   );
  // }

  update(record: any): Observable<any> {
    return this.http
      .put(`${AppConfig.URL_Products}${record["id"]}/`, record)
      .pipe(
        tap(_ => this.log(`updated record id=${record["id"]}`)),
        catchError(this.handleError<any>("updateRecord"))
      );
  }

  updateImageOrder(record: any): Observable<any> {
    return this.http
      .put(`${AppConfig.URL_Product_Images}${record["productimage_id"]}/`, record)
      .pipe(
        tap(_ => this.log(`updated record id=${record["productimage_id"]}`)),
        catchError(this.handleError<any>("updateRecord"))
      );
  }

  removeProductImage(productimage_id: any): Observable<any> {
    return this.http
      .get(`${AppConfig.URL_Product_Images_Delete}${productimage_id}/`)
      .pipe(
        tap(_ => this.log(`updated record id=${productimage_id}`)),
        catchError(this.handleError<any>("deleteRecord"))
      );
  }

  updateProductImage(record: any): Observable<any> {
    return this.http
      .post(`${AppConfig.URL_Product_Images_Update}${record['product']}/`, record)
      .pipe(
        tap(_ => this.log(`updated record id=${record['product']}`)),
        catchError(this.handleError<any>("deleteRecord"))
      );
  }

  updateApproved(record: any): Observable<any> {
    return this.http
      .put(`${AppConfig.URL_ProductsApproved}${record["id"]}/`, record)
      .pipe(
        tap(_ => this.log(`updated record id=${record["id"]}`)),
        catchError(this.handleError<any>("updateRecord"))
      );
  }

  bulkUpdate(record: any): Observable<any> {
    return this.http
      .put(`${AppConfig.URL_Products}bulk/`, record)
      .pipe(
        tap(_ => this.log(`updated record id=${record["id"]}`)),
        catchError(this.handleError<any>("updateRecord"))
      );
  }

  delete(id) {
    return this.http.delete<any>(`${AppConfig.URL_Products}${id}/`).pipe(
      map(x => x),
      tap(_ => console.log(`delete record=${id}`)),
      catchError(this.handleError<any>("deleteRecord"))
    );
  }

  rejected(record: any) {
    return this.http.post<any>(`${AppConfig.URL_Products_Reject}product/`,record).pipe(
      map(x => x),
      tap(_ => console.log(`delete record=${record["id"]}`)),
      catchError(this.handleError<any>("rejectedRecord"))
    );
  }

  bulkDelete(id) {
    return this.http.post<any>(`${AppConfig.URL_Products}bulk/delete/`, id).pipe(
      map(x => x),
      tap(_ => console.log(`delete record=${id}`)),
      catchError(this.handleError<any>("deleteRecord"))
    );
  }

  bulkDiscard(id) {
    return this.http.post<any>(`${AppConfig.URL_Products}reject/bulk/`, id).pipe(
      map(x => x),
      tap(_ => console.log(`delete record=${id}`)),
      catchError(this.handleError<any>("discardRecord"))
    );
  }

  bulkAccept(id) {
    return this.http.post<any>(`${AppConfig.URL_Products}approve/bulk/`, id).pipe(
      map(x => x),
      tap(_ => console.log(`approve record=${id}`)),
      catchError(this.handleError<any>("ApproveRecord"))
    );
  }

  bulkPendingApprove() {
    return this.http.get(`${AppConfig.URL_Products}unapproved_pending_ids/`).pipe(
      map(x => x),
      tap(_ => console.log(`approve bulk record`)),
      catchError(this.handleError<any>("ApproveRecord"))
    );
  }

  

  getAllHidden(page: Page): Observable<any> {
    let page_number = page.pageNumber+1;
    let page_params = 'page='+page_number;
    return this.http
      .get<any[]>(`${AppConfig.URL_Products + "unapproved/"}?${page_params}`)
      .pipe(
        map(x => x),
        tap(_ => console.log("fetched record")),
        catchError(this.handleError("getRecord", []))
      );
  }

  getExportParams(){
    return this.http.get<any>(`${AppConfig.UPLOAD_ExportFile}`).pipe(
      map(x => x ),
      tap(_ => console.log(`get record`)),
      catchError(this.handleError<any>('getRecord'))
    );
  }

  API_FORM_POST_File(url: string, file, body?: any) {
    var form_data = new FormData();

    form_data.append('key', body.key);
    form_data.append('AWSAccessKeyId', body.AWSAccessKeyId);
    form_data.append('acl', body.acl);
    form_data.append('success_action_status', '201');
    form_data.append('policy', body.policy);
    form_data.append('signature', body.signature);
    form_data.append('Content-Type', 'application/*');
    form_data.append('file', file);    

    const httpOptions = {
      headers: new HttpHeaders({
      }),
      responeType:'text',
      Observe: 'response'
    };

    httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    httpOptions.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

    return this.http.post(url, form_data, httpOptions ).pipe(
      map(x => x),
      tap((newP: any) => console.log(`added record w/ id=${newP}`)),
      catchError(this.handleError<any>('add'))
    );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // alert(error);
      console.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // this.ts.error("Failed to Perform Operation");
      // // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a  message with the MessageService */
  private log(message: string) {
    //  alert(message)
    console.log(message);
    // this.ts.success("Operation Performed Successfully");
    // this.messageService.add(`DealerService: ${message}`);
  }
  
}


