import { Page } from './../../pages/full-layout-page/page';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../constants/app-config';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class  ActivityService  {

  constructor(private http: HttpClient,) {
  }

  getAll() {
    return this.http.get<any []>(`${AppConfig.URL_ActivityNotifications}`)
    .pipe(
      map(x => x ),
      tap(_ => console.log('fetched record')),
      catchError(this.handleError('getRecord', []))
    );
  }

  getRecentActivities(params) {
    return this.http.get<any []>(`${AppConfig.URL_ActivityNotifications}${params}`)
    .pipe(
      map(x => x ),
      tap(_ => console.log('fetched record')),
      catchError(this.handleError('getRecord', []))
    );
  }


  getAllPaginationedActivity(page: Page): Observable<any>
  {
    let page_number = page.pageNumber+1;
    let page_params = 'page='+page_number;
  
    if(page.search!=''){
      page_params += '&search='+page.search;
    }

    if(page.user!=0){
      page_params += '&user='+page.user;
    }

    if(page.from_date!='' && page.to_date){
      page_params += '&from_date='+page.from_date;
      page_params += '&to_date='+page.to_date;
    }

    
  
    return this.http.get<any[]>(`${AppConfig.URL_ActivityNotifications}?${page_params}`).pipe(
      map(x => x),
      tap(_ => console.log("fetched record")),
      catchError(this.handleError("getRecord", []))
    );
  }
  

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // alert(error);
      console.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // this.ts.error("Failed to Perform Operation");
      // // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a  message with the MessageService */
  private log(message: string) {
      console.log(message)
  }

}


