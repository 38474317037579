import { Injectable } from '@angular/core';
// import { BaseHttpService } from '../../services/BaseHttpService';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../constants/app-config';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
// import { Adapter } from './Adapter';


// import { ToastrService } from 'ngx-toastr';

import { Page } from './page';

@Injectable({
  providedIn: 'root'
})
export class  FranchiseBrandService  {

  constructor(private http: HttpClient, 
    // private ts: ToastrService
    ) {
    // super(http);
}

add (record: any): Observable<any> {

  return this.http.post<any>(`${AppConfig.URL_FranchiseBrand}`, record).pipe(
    map(x => x ),
    tap((newP: any) => console.log(`added record w/ id=${newP}`)),
    catchError(this.handleError<any>('add'))
  );
}

rejected(id) {
    return this.http.post<any>(`${AppConfig.URL_Products_Reject}franchisebrand/`,id).pipe(
      map(x => x),
      tap(_ => console.log(`delete record=${id}`)),
      catchError(this.handleError<any>("deleteRecord"))
    );
  }

getAll() 
{

  return this.http.get<any []>(`${AppConfig.URL_FranchiseBrand}`)
  .pipe(
    map(x => x ),
    tap(_ => console.log('fetched record')),
    catchError(this.handleError('getRecord', []))
  );
}

getAllFranchiseBrandsPagination(page: Page): Observable<any>
{
  let page_number = page.pageNumber+1;
  let page_params = 'page='+page_number;

  if(page.search!=''){
    page_params += '&search='+page.search;
  }

  console.log('page-data>>>',page)
  return this.http.get<any[]>(`${AppConfig.URL_FranchiseBrand}?${page_params}`).pipe(
    map(x => x),
    tap(_ => console.log("fetched record")),
    catchError(this.handleError("getRecord", []))
  );
}

getAllHidden() 
{

  return this.http.get<any []>(`${AppConfig.URL_FranchiseBrand + "unapproved/" }`)
  .pipe(
    map(x => x ),
    tap(_ => console.log('fetched record')),
    catchError(this.handleError('getRecord', []))
  );
}

getAllHiddenPagination(page: Page): Observable<any>
{
  let page_number = page.pageNumber+1;
  let page_params = 'page='+page_number;

  if(page.search!=''){
    page_params += '&search='+page.search;
  }

  console.log('page-data>>>',page)
  return this.http.get<any[]>(`${AppConfig.URL_FranchiseBrand + "unapproved/"}?${page_params}`).pipe(
    map(x => x),
    tap(_ => console.log("fetched record")),
    catchError(this.handleError("getRecord", []))
  );
}

get(id){
  return this.http.get<any>(`${AppConfig.URL_FranchiseBrand}${id}/`).pipe(
    map(x => x ),
    tap(_ => console.log(`get record=${id}`)),
    catchError(this.handleError<any>('getRecord'))
  );
}

getUnapprove(id){
  return this.http.get<any>(`${AppConfig.URL_FranchiseBrand}${id}/unapprove/`).pipe(
    map(x => x ),
    tap(_ => console.log(`get record=${id}`)),
    catchError(this.handleError<any>('getRecord'))
  );
}

updateUnapprove (record: any): Observable<any> {
  return this.http.put(`${AppConfig.URL_FranchiseBrand}${record["id"]}/unapprove/`, record).pipe(
    tap(_ => this.log(`updated record id=${record["id"]}`)),
    catchError(this.handleError<any>('updateRecord'))
  );
}

approve (record: any): Observable<any> {
  return this.http.put(`${AppConfig.URL_FranchiseBrand}${record["id"]}/approve/`, record).pipe(
    tap(_ => this.log(`updated record id=${record["id"]}`)),
    catchError(this.handleError<any>('updateRecord'))
  );
}

getAllFranchiseGroup() 
{

  return this.http.get<any []>(`${AppConfig.URL_FranchiseGroup}`)
  .pipe(
    map(x => x ),
    tap(_ => console.log('fetched record')),
    catchError(this.handleError('getRecord', []))
  );
}

getFieldPermissions(id){
  return this.http.get<any>(`${AppConfig.URL_FranchiseBrandPermissionCheck + "?role="}${id}`).pipe(
    map(x => x ),
    tap(_ => console.log(`get record=${id}`)),
    catchError(this.handleError<any>('getRecord'))
  );
}

update (record: any): Observable<any> {
  return this.http.put(`${AppConfig.URL_FranchiseBrand}${record["id"]}/`, record).pipe(
    tap(_ => this.log(`updated record id=${record["id"]}`)),
    catchError(this.handleError<any>('updateRecord'))
  );
}

delete(id){
  return this.http.delete<any>(`${AppConfig.URL_FranchiseBrand}${id}/`).pipe(
    map(x => x ),
    tap(_ => console.log(`delete record=${id}`)),
    catchError(this.handleError<any>('deleteRecord'))
  );
}

getUserRole(id) 
{

  return this.http.get<any>(`${AppConfig.URL_UserRole + "?user="}${id}`)
  .pipe(
    map(x => x ),
    tap(_ => console.log('fetched record')),
    catchError(this.handleError('getRecord', []))
  );
}



private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    // alert(error);
    console.log(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    // this.log(`${operation} failed: ${error.message}`);
    // this.ts.error("Failed to Perform Operation");
    // // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

/** Log a  message with the MessageService */
private log(message: string) {
  //  alert(message)
    console.log(message)
  // this.ts.success("Operation Performed Successfully");
  // this.messageService.add(`DealerService: ${message}`);
}

getAllParentCategory(query): Observable<any>{

  return this.http.get<any []>(`${AppConfig.URL_FranchiseBrand}${query}`)
  .pipe(
    map(x => x ),
    tap(_ => console.log('fetched record')),
    catchError(this.handleError('getRecord', []))
  );
}

 getAllUnApprovedParentCategory(query): Observable<any>{

   return this.http.get<any []>(`${AppConfig.URL_FranchiseBrand}unapproved/${query}`)
   .pipe(
     map(x => x ),
     tap(_ => console.log('fetched record')),
     catchError(this.handleError('getRecord', []))
   );
 }

 bulkDiscard(id) {
    return this.http.post<any>(`${AppConfig.URL_FranchiseBrand}reject/bulk/`, id).pipe(
      map(x => x),
      tap(_ => console.log(`delete record=${id}`)),
      catchError(this.handleError<any>("discardRecord"))
    );
  }

 bulkAccept(id) {
    return this.http.post<any>(`${AppConfig.URL_FranchiseBrand}approve/bulk/`, id).pipe(
      map(x => x),
      tap(_ => console.log(`approve record=${id}`)),
      catchError(this.handleError<any>("ApproveRecord"))
    );
  }

}


